import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra6-360/image.webp);
  background-position: center;
  background-size: cover;
  padding: 32px 0;

  @media (min-width: ${breakpoints.md}){
    padding: 74px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra6-768/image.webp);
  }
  @media (min-width: 1024px){
    padding: 100px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra6-1024/image.webp);
  }
  @media (min-width: ${breakpoints.xxl}){
    padding: 97px 0 102px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra6-1440/image.webp);
  }
`

export const NotaACard = styled.div`
  background-color: white;
  border-radius: 32px;
  padding: 48px 32px;

  @media (min-width: ${breakpoints.md}){

  }

  h2{
    color: ${orange.extra};
    font-family: "Citrina", Helvetica, sans-serif;
    font-weight: 700;
    font-size: 66px;
    line-height: 52.8px;
    margin-bottom: 24px;

    @media (min-width: 1024px) {
      font-size: 97px;
    }
    span{
      display: block;
      line-height: 22px;
      font-size: 20px;
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      color: ${grayscale[500]};

      @media (min-width: ${breakpoints.md}){
        font-size: 36px;
        line-height: 39.6px;
        padding-top: 9px;
      }
    }
  }
  p{
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: ${grayscale[400]};
    margin: 0;
  }
`
