import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'
import { colorScheme } from '../../styles'
import { breakpoints } from './../../../../../styles/breakpoints'

export const ContentHeader = styled.section`
  padding: 48px 0;
  background-color: ${orange.extra};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  @media (min-width: ${breakpoints.md}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra4-768/image.webp);
    padding: 67px 0;
  }

  @media (min-width: 1024px){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra4-1024/image.webp);
    padding: 89px 0 93px 0;
  }

  @media (min-width: ${breakpoints.xxl}){
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/api-pix-dobra4-1440/image.webp);
    padding: 97px 0 67px 0;
  }

  .divPrincipal{
    @media (min-width: ${breakpoints.md}){
      background-color: white;
      padding: 48px 32px;
      border-radius: 32px;
    }
  }
`
export const SectionTitle = styled.h2`
  text-align: center;
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
  margin: 0;
  color: white;

  @media (min-width: ${breakpoints.md}){
    text-align: left;
    color: ${colorScheme.darkGray};
  }

  @media (min-width: 1024px){
    font-size: 40px;
    line-height: 44px;
  }

  @media (min-width: ${breakpoints.xxl}){
    font-size: 48px;
    line-height: 52.8px;
  }
`

export const StyledUl = styled.ul`
  @media (min-width: ${breakpoints.md}) {
    
  }
  li{
    list-style: none ;
    display: flex;
    flex-direction: row;
    margin: 24px 0;

    .icon{
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }
`
export const StyledH3Li = styled.h3`
  color: white;
  line-height: 21.78px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Inter", Helvetica, sans-serif;
  margin: 0 0 8px 0;

  @media (min-width: ${breakpoints.md}){
    color: ${colorScheme.darkOrange};
  }
`

export const StyledParagrafer = styled.p`
  color: white;
  line-height: 19.36px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", Helvetica, sans-serif;
  margin: 0;
  @media (min-width: ${breakpoints.md}){
    color: ${grayscale[400]};
  }
`
export const TextsDiv = styled.div`
  display: flex;
  flex-direction: column;
`
